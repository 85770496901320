import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { CardContent } from '../../../components/card'
import { Box, Row } from 'jsxstyle'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import { useQuery } from '../../../store/api'

export const ReservationSummaryBox = ({
  reservation,
  currencyFormat,
  isCancelled,
  isFullyPaid,
  isDepositFeePaid
}) => {

  const [{ data: bookingCodes, loading, error }, dispatchBookingCodeQuery] =
    useQuery({
      path: 'travelbooking/bookingcodes',
      initParams: {
        reservationCode: reservation?.reservationCode
      },
      manual: true
    })

  useEffect(() => {
    if (
      reservation &&
      isNorwegianFlight(reservation.charter) &&
      !bookingCodes &&
      !loading &&
      !error
    ) {
      dispatchBookingCodeQuery()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCodes, loading, reservation, error])

  const isNorwegianFlight = charter => {
    if (!charter?.flights) return false
    return charter.flights.some(f => f.airline === 'DY')
  }

  const shouldReceive = reservation.price <= reservation.paid

  const getDepositFeePaidDate = reservation => {
    if (!reservation.payments.length || !reservation.depositFee) return null
    let sum = 0
    let date = null

    for (let i = 0; i < reservation.payments.length; i++) {
      const payment = reservation.payments[i]
      sum += payment.amount
      if (sum >= reservation.depositFee) {
        date = payment.dateTime
        break
      }
    }

    return date
  }

  return (
    <Box background='rgb(255, 249, 203)'>
      <CardContent>
        <h4>{Y`Summary of reservation`}</h4>
        <Row>
          <Box width='100%' className='nomargin' component='p'>
            {Y`Reservation number`}:
          </Box>
          <Box width='100%' className='nomargin' component='p'>
            {reservation.reservationCode}
          </Box>
        </Row>
        <Row>
          <Box width='100%' className='nomargin' component='p'>
            {Y`Reservation date`}:
          </Box>
          <Box width='100%' className='nomargin' component='p'>
            {moment(reservation.firstConfirmationDate).format('YYYY-MM-DD')}
          </Box>
        </Row>
        {!moment(reservation.firstConfirmationDate).isSame(
          moment(reservation.lastConfirmationDate)
        ) && (
          <Row>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Last updated`}:
            </Box>
            <Box width='100%' className='nomargin' component='p'>
              {moment(reservation.lastConfirmationDate).format('YYYY-MM-DD')}
            </Box>
          </Row>
        )}
        <Row>
          <Box width='100%' className='nomargin' component='p'>
            {Y`Booked by`}:
          </Box>
          <Box width='100%' className='nomargin' component='p'>
            {reservation.payingCustomer.firstName}{' '}
            {reservation.payingCustomer.lastName}
          </Box>
        </Row>
        <Row>
          <Box width='100%' className='nomargin' component='p'>
            {Y`Total price`}:{' '}
          </Box>
          <Box width='100%' className='nomargin' component='p'>
            {currencyFormat.format(reservation.price)}
          </Box>
        </Row>
        {!isCancelled && reservation.depositDueDate && (
          <Row>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Deposit fee`}:{' '}
            </Box>
            <Box width='100%' className='nomargin' component='p'>
              {currencyFormat.format(reservation.depositFee)}{' '}
              {isDepositFeePaid ? Y`paid` : Y`pay latest`}{' '}
              <Box component='span' whiteSpace='nowrap'>
                {moment(
                  isDepositFeePaid
                    ? getDepositFeePaidDate(reservation)
                    : reservation.depositDueDate
                ).format('YYYY-MM-DD HH:mm')}
              </Box>
            </Box>
          </Row>
        )}

        {isCancelled && (
          <Row>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Paid`}:{' '}
            </Box>
            <Box width='100%' className='nomargin' component='p'>
              {currencyFormat.format(reservation.paid)}
            </Box>
          </Row>
        )}

        {isCancelled && reservation.status !== 'Expired' && (
          <Row>
            <Box width='100%' className='nomargin' component='p'>
              {shouldReceive ? Y`To receive` : Y`To pay`}:{' '}
            </Box>
            <Box width='100%' className='nomargin' component='p'>
              {currencyFormat.format(
                shouldReceive
                  ? reservation.paid - reservation.price
                  : reservation.price - reservation.paid
              )}
            </Box>
          </Row>
        )}

        {!isCancelled && (
          <Row>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Final payment`}:{' '}
            </Box>
            {isFullyPaid ? (
              <Box width='100%' className='nomargin' component='p'>
                {Y`paid`}{' '}
                {moment(reservation.lastPaymentDate).format('YYYY-MM-DD')}
              </Box>
            ) : (
              <Box width='100%' className='nomargin' component='p'>
                {isDepositFeePaid
                  ? currencyFormat.format(reservation.price - reservation.paid)
                  : currencyFormat.format(
                      reservation.price - reservation.depositFee
                    )}{' '}
                {isFullyPaid ? Y`paid` : Y`pay latest`}{' '}
                {isFullyPaid
                  ? moment(reservation.lastPaymentDate).format('YYYY-MM-DD')
                  : moment(reservation.fullPaymentDueDate).format(
                      `YYYY-MM-DD${
                        moment(reservation.fullPaymentDueDate).format('Hms') ===
                          '000' ||
                        moment
                          .parseZone(reservation.fullPaymentDueDate)
                          .format('Hms') === '000'
                          ? ''
                          : ' HH:mm'
                      }`
                    )}
              </Box>
            )}
          </Row>
        )}

        {reservation.payments.length > 0 && (
          <Row marginTop='.9375rem'>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Payments`}:{' '}
            </Box>
            <Box width='100%'>
              {reservation.payments.map(payment => (
                <Box
                  width='100%'
                  key={payment.dateTime}
                  className='nomargin'
                  component='p'
                  marginBottom='.4687rem !important'
                >
                  <Currency>{payment.amount}</Currency> {Y`paid`}{' '}
                  <Box component='span' whiteSpace='nowrap'>
                    {moment(payment.dateTime).format('YYYY-MM-DD, HH:mm')}
                  </Box>
                </Box>
              ))}
            </Box>
          </Row>
        )}
        {bookingCodes && bookingCodes.length > 0 && (
          <Row marginTop='.9375rem'>
            <Box width='100%' className='nomargin' component='p'>
              {Y`Norwegian booking codes`}:
            </Box>
            <Box width='100%' className='nomargin' component='p'>
              {[...new Set(bookingCodes.map(bc => bc.bookingCode))].join(', ')}
            </Box>
          </Row>
        )}
      </CardContent>
    </Box>
  )
}
