import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import { Row } from 'jsxstyle'
import ExcursionDialog from './excursion-dialog'
import Button from '../../../components/button'
import Skeleton from '../../../components/skeleton'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import { groupBy } from '../../../utils/group-by'
import Y from '../../../y18n'
import * as addonsActions from '../../../store/ducks/addons'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  addonsState: state.addons.items,
  loading: state.addons.loading,
  marketId: state.market.market.id
})
const mapDispatchToProps = dispatch => ({
  addonsActions: bindActionCreators(addonsActions, dispatch)
})

class ExcursionList extends Component {
  state = { selected: {} }

  componentDidMount () {
    this.props.addonsActions.queryAddons(this.props.reservationId, 'Excursion')
  }

  handleSelectAddon (addonType, value, articleId, travelerId, travelerIndex) {
    let reservationId = this.props.reservationId
    if (value) {
      this.props.addonsActions.submitAddAddon({
        addonType,
        reservationId,
        articleId,
        travelerId,
        dontBlock: true
      })

      // add to other travelers if first traveler
      if (travelerIndex === 0) {
        this.props.addonsState
          .find(addon => addon.type === addonType)
          .travelers.filter(x => x.id !== travelerId)
          .forEach(t => {
            if (
              t.articles.find(x => !x.reserved && x.articleId === articleId)
            ) {
              this.props.addonsActions.submitAddAddon({
                addonType,
                reservationId,
                articleId,
                travelerId: t.id,
                dontBlock: true
              })
            }
          })
      }
    } else
      this.props.addonsActions.submitRemoveAddon({
        addonType,
        reservationId,
        articleId,
        travelerId
      })
  }

  isReserved (excursion) {
    return excursion.travelers.some(t =>
      t.articles
        .filter(a => a.description === excursion.description)
        .some(x => x.reserved)
    )
  }

  renderButtonText (excursion) {
    return this.isReserved(excursion)
      ? `${
          excursion.travelers.filter(t =>
            t.articles
              .filter(a => a.description === excursion.description)
              .some(x => x.reserved)
          ).length
        } ${Y`Travelers`}`
      : Y`Select`
  }

  renderExcursionGroup (group, allArticles) {
    const title =
      group.groupIndicator === 'undefined'
        ? Y`Addons`
        : group.items[0].groupNames[this.props.marketId]
    return (
      <Card
        key={group.groupIndicator}
        id={
          group.groupIndicator === 'undefined'
            ? 'Excursion'
            : group.groupIndicator
        }
      >
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <Row
          mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
          smFlexDirection='column'
        >
          <List className='no-odd border'>
            {group.items
              .sort((a, b) => a.groupSortingOrder - b.groupSortingOrder)
              .map((excursion, k) => (
                <ListItem key={excursion.description}>
                  <Row justifyContent='space-between' alignItems='center'>
                    <div>
                      <Row width='100%'>
                        <h3 className='nomargin'>{`${excursion.description} ${
                          excursion.dayOfWeek
                            ? ' - ' +
                              moment.parseZone(excursion.date).format('dddd') +
                              (excursion.numberOfDays !== 1
                                ? ' - ' +
                                  moment
                                    .parseZone(excursion.date)
                                    .add(excursion.numberOfDays - 1, 'days')
                                    .format('dddd')
                                : '')
                            : ''
                        }`}</h3>

                        <p style={{ margin: 0 }}>
                          {allArticles.filter(
                            a => a.description === excursion.description
                          ).length > 1
                            ? ''
                            : ' - '}
                          {allArticles.filter(
                            a => a.description === excursion.description
                          ).length > 1 ? (
                            ''
                          ) : (
                            <Currency>{excursion.priceAmount}</Currency>
                          )}
                        </p>
                      </Row>
                      <p className='caption'>{excursion.info}</p>
                    </div>

                    <div className='text-right'>
                      <Button
                        key={k}
                        small
                        link={this.isReserved(excursion)}
                        nomargin
                        style={{
                          color: this.isReserved(excursion)
                            ? '#47a404'
                            : 'rgb(8, 154, 208)',
                          borderColor: 'rgb(8, 154, 208)'
                        }}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showDialog: `${excursion.articleId}`
                          })
                        }
                      >
                        {this.renderButtonText(excursion)} »
                      </Button>
                      {this.state.showDialog === `${excursion.articleId}` && (
                        <ExcursionDialog
                          key={k + 'd'}
                          excursion={excursion}
                          onClose={() =>
                            this.setState({ ...this.state, showDialog: null })
                          }
                          onSelect={(
                            value,
                            articleId,
                            travelerId,
                            travelerIndex
                          ) => {
                            this.handleSelectAddon(
                              'Excursion',
                              value,
                              articleId,
                              travelerId,
                              travelerIndex
                            )
                          }}
                        />
                      )}
                    </div>
                  </Row>
                </ListItem>
              ))}
          </List>
        </Row>
      </Card>
    )
  }

  render () {
    if (this.props.loading) {
      return (
        <Card>
          <CardHeader>
            <CardTitle>
              <Skeleton style={{ width: '30%' }} />
            </CardTitle>
            <p>
              <Skeleton style={{ width: '60%' }} />
            </p>
          </CardHeader>
        </Card>
      )
    }

    let addons = JSON.parse(JSON.stringify(this.props.addonsState))
    if (!addons.length || !addons.some(a => a.type === 'Excursion')) return null
    let excursions = []
    addons
      .find(a => a.type === 'Excursion')
      .travelers.forEach(traveler => {
        traveler.articles.forEach(article => {
          if (article.groupNames) {
            article.categoryId = `${article.groupNames['SE']}_${article.groupNames['NO']}_${article.groupNames['FI']}`
          }
          let existingExcursionIndex = excursions.findIndex(
            e => e.description === article.description
          )
          if (existingExcursionIndex > -1) {
            excursions[existingExcursionIndex].travelers = !excursions[
              existingExcursionIndex
            ].travelers
              ? [traveler]
              : [...excursions[existingExcursionIndex].travelers, traveler]
          } else {
            excursions.push({ ...article, travelers: [traveler] })
          }
        })
      })

    let allArticles = [].concat.apply(
      [],
      addons.find(a => a.type === 'Excursion').travelers.map(t => t.articles)
    )
    let groupedExcursions = groupBy(excursions, 'categoryId')

    const withoutUngrouped = groupedExcursions.filter(
      g => g.groupIndicator !== 'undefined'
    )
    withoutUngrouped.sort((a, b) => {
      if (a.groupIndicator > b.groupIndicator) return 1
      else if (a.groupIndicator < b.groupIndicator) return -1
      return 0
    })
    const ungrouped = groupedExcursions.filter(
      g => g.groupIndicator === 'undefined'
    )
    groupedExcursions = withoutUngrouped.concat(ungrouped)
    return (
      <>
        {groupedExcursions.map(group =>
          this.renderExcursionGroup(group, allArticles)
        )}
      </>
    )
  }
}

ExcursionList = connect(mapStateToProps, mapDispatchToProps)(ExcursionList)

export default ExcursionList
