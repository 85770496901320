import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Page, Layout, Content } from '../../components/layout'
import ReservationSummary from '../../containers/reservation-summary'
import Button from '../../components/button'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Box, Row, Col } from 'jsxstyle'
import { Icon } from '../../components/icon'
import { getRedirectUrl } from '../../components/redirect-service'
import { CardContent, Card, CardHeader, CardTitle } from '../../components/card'
import { ReservationSummaryBox } from './containers/reservation-summary-box'
import PaymentAlternativesDialog from '../../containers/payment-alternatives-dialog'
import Y from '../../y18n'
import {
  syncPayments,
  resetCurrentTransaction,
  submitCardTransaction
} from '../../store/ducks/payment'
import {
  submitConfirmUpdates,
  queryReservationSummary
} from '../../store/ducks/reservation'
import ShortReservationSummary from '../../containers/short-reservation-summary'
import './payment-form.css'

const mapStateToProps = state => ({
  reservation: state.reservation.summary,
  paymentState: state.payment,
  market: state.market.market
})

class ReservationDetailsPage extends Component {
  state = {
    showPaymentDialog: false,
    payDeposit: false,
    iframeActivated: false,
    useTestTerminal: false
  }

  componentDidMount () {
    let reservationId = this.props.match.params.id
    const params = new URLSearchParams(this.props.location.search)
    const errorMessage = params.get('errorMessage')
    if (errorMessage) {
      var failedMessage = Y`Payment failed`
      toast.error(`${failedMessage}: ${errorMessage}`)
    }
    const useTestTerminal = params.get('useTestTerminal')
    if (useTestTerminal === 'true') {
      this.setState({ ...this.state, useTestTerminal: true })
    }
    this.props
      .dispatch(queryReservationSummary(reservationId, true))
      .then(res => {
        let status = params.get('status')
        if (status) {
          if (status.toLowerCase() === 'newdepositpayment') {
            this.handleToggleDialog(!this.depositFeePaid(res.data))
          } else if (status.toLowerCase() === 'newfullpayment') {
            this.handleToggleDialog()
          }
        }
      })
    this.props.dispatch(syncPayments(reservationId))
    // Empty data layer
    if (window.dataLayer) {
      setTimeout(() => {
        window.dataLayer = []
      }, 4000)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.paymentState.embeddedUrl && !this.state.iframeActivated) {
      // Inject AltaPay script to body, to show payment iframe
      // Potential Threat Modeling candidate
      const abortController = new AbortController()
      const script = document.createElement('script')
      script.src = nextProps.paymentState.embeddedUrl
      document.body.appendChild(script)
      this.setState({
        ...this.state,
        showPaymentDialog: false,
        iframeActivated: true
      })
      // When close button is pressed in the iframe. this listener should remove the script
      window.addEventListener(
        'message',
        e => {
          if (
            e.isTrusted &&
            e.origin.includes('altapay') &&
            e.data === 'close'
          ) {
            document.body.removeChild(script)
            this.setState({
              ...this.state,
              iframeActivated: false,
              showPaymentDialog: false
            })
            // Dismiss this listener and create a new one when iframe opens again
            abortController.abort()
            this.props.dispatch(resetCurrentTransaction())
          }
        },
        { signal: abortController.signal }
      )
      return
    }
    // redirect to app after payment succeded if we come from it
    if (
      nextProps.paymentState.paymentSucceded !== null &&
      this.props.paymentState.paymentSucceded
    ) {
      const redirectUrl = getRedirectUrl()
      if (redirectUrl) {
        window.location = redirectUrl
      }
    }
    return null
  }

  handleScrollToSummary = () => {
    let elem = document.querySelector('#res-summary-details')
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' })
    }
  }

  fullyPaid (reservation) {
    return reservation.paid >= reservation.price
  }

  depositFeePaid (reservation) {
    return reservation.paid >= reservation.depositFee
  }

  depositFeePaidDate (reservation) {
    if (!reservation.payments.length || !reservation.depositFee) return null
    let sum = 0
    let date = null

    for (let i = 0; i < reservation.payments.length; i++) {
      const payment = reservation.payments[i]
      sum += payment.amount
      if (sum >= reservation.depositFee) {
        date = payment.dateTime
        break
      }
    }

    return date
  }

  hasChanges (reservation) {
    return reservation.status === 'ConfirmedWithChanges'
  }

  isCancelled (reservation) {
    return (
      reservation.status === 'Cancelled' || reservation.status === 'Expired'
    )
  }

  handlePaymentClick (type, amount, id) {
    let reservation = this.props.reservation
    const customerInfo = {
      phone: reservation.payingCustomer.cellPhoneNumber,
      email: reservation.payingCustomer.emailAddress
    }

    if (type) {
      this.props.dispatch(
        submitCardTransaction(
          reservation.id,
          amount,
          reservation.currency,
          type,
          reservation.marketId,
          reservation.reservationCode,
          customerInfo,
          id
        )
      )
    }
  }

  handleToggleDialog (payDeposit = false) {
    this.setState({
      ...this.state,
      payDeposit: payDeposit,
      showPaymentDialog: !this.state.showPaymentDialog
    })
  }

  handleConfirmUpdates () {
    this.props.dispatch(
      submitConfirmUpdates({
        reservationId: this.props.reservation.id
      })
    )
  }

  renderHeaderText (reservation, status) {
    let text = reservation.reservationCode

    if (this.hasChanges(reservation)) {
      return Y`You have made changes to the booking`
    }

    if (status === 'confirmed') {
      text =
        reservation.numberOfConfirmations > 1
          ? Y`Your reservation is updated`
          : Y`Thanks for your reservation`
    } else if (status === 'paymentsucceeded') {
      if (reservation.paid === reservation.depositFee) {
        text = Y`Deposit fee is paid`
      } else if (reservation.paid >= reservation.price) {
        text = Y`Your trip is now paid`
      } else {
        text = Y`Payment succeded`
      }
    } else if (status === 'paymentfailed') {
      text = Y`Payment failed`
    } else {
      text = `${Y`Welcome back`} ${reservation.payingCustomer.firstName}`
    }

    return text
  }

  renderInfoText (reservation, status) {
    if (this.hasChanges(reservation)) return null

    if (this.isCancelled(reservation)) {
      return Y`The reservation is canceled`
    } else if (status === 'confirmed') {
      return Y`Your reservation has now been completed. Your reservation number is ${`<strong>${reservation.reservationCode}</strong>`} and we have sent a confirmation to your e-mail address ${
        reservation.payingCustomer.emailAddress
      }`
    } else if (status === 'paymentsucceded') {
      if (reservation.paid === reservation.depositFee) {
        return Y`Thank you for payment. Do not forget that the final settlement should be paid latest at ${moment(
          reservation.fullPaymentDueDate
        ).format(
          `YYYY-MM-DD${
            moment(reservation.fullPaymentDueDate).format('Hms') === '000' ||
            moment.parseZone(reservation.fullPaymentDueDate).format('Hms') ===
              '000'
              ? ''
              : ' HH:mm'
          }`
        )}`
      } else if (reservation.paid >= reservation.price) {
        return Y`Thank you for payment. Your trip is now fully paid`
      } else {
        return Y`Thank you for payment. Do not forget that the final settlement should be paid latest at ${moment(
          reservation.fullPaymentDueDate
        ).format(
          `YYYY-MM-DD${
            moment(reservation.fullPaymentDueDate).format('Hms') === '000' ||
            moment.parseZone(reservation.fullPaymentDueDate).format('Hms') ===
              '000'
              ? ''
              : ' HH:mm'
          }`
        )}`
      }
    } else if (status === 'paymentfailed') {
      return Y`Payment failed`
    }
    return Y`Here you can change, pay and manage your trip with us`
  }

  render () {
    const { reservation, market } = this.props
    if (!reservation || !reservation.payingCustomer) return null

    const currencyFormat = new Intl.NumberFormat(market.culture, {
      style: 'currency',
      currency: reservation.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    const mediaQueries = { sm: 'screen and (max-width: 992px)' }

    const params = new URLSearchParams(this.props.location.search)
    let status = params.get('status')
    if (status) status = status.toLowerCase()

    return (
      <Page>
        <Layout>
          {/* <ReservationSummary /> */}
          <Content>
            {this.state.showPaymentDialog && (
              <PaymentAlternativesDialog
                marketId={reservation.marketId}
                currency={reservation.currency}
                useTestTerminal={this.state.useTestTerminal}
                amount={
                  this.state.payDeposit
                    ? reservation.depositFee
                    : reservation.price - reservation.paid
                }
                minAmountToPay={
                  reservation.depositDueDate
                    ? reservation.minPayment
                    : reservation.maxPayment
                }
                maxAmountToPay={reservation.maxPayment}
                onClose={() => this.handleToggleDialog()}
                onPay={(type, amount, id) =>
                  this.handlePaymentClick(type, amount, id)
                }
              />
            )}
            <Box component='h2' textAlign='center'>
              {this.renderHeaderText(reservation, status)}!
            </Box>

            {this.hasChanges(reservation) && (
              <Card>
                <CardContent>
                  <ShortReservationSummary />
                </CardContent>
              </Card>
            )}

            {reservation.requireCustomerConfirmation && (
              <Card
                style={{
                  background: 'rgb(240, 240, 240)',
                  marginBottom: '.9375rem'
                }}
              >
                <CardHeader
                  style={{
                    color: 'rgba(195, 77, 77)',
                    background: 'rgb(255, 212, 212)'
                  }}
                >
                  <Row justifyContent='center' alignItems='flex-start'>
                    <Icon size='48px'>warning</Icon>
                    <Box marginLeft='.9375rem'>
                      <CardTitle style={{ textAlign: 'left' }}>
                        {Y`A change that affects the flight of your reservation has taken place`}
                        !
                      </CardTitle>
                      <p className='nomargin'>
                        {Y`You must now confirm that you have read the information about this by clicking on the button below`}
                        .
                      </p>
                    </Box>
                  </Row>
                </CardHeader>
                <CardContent style={{ maxWidth: 640, margin: '0 auto' }}>
                  <pre
                    style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}
                  >
                    {reservation.updateMessage}
                  </pre>
                  <Col alignItems='center'>
                    <Button
                      style={{ whiteSpace: 'pre-wrap' }}
                      primary
                      onClick={() => this.handleConfirmUpdates()}
                    >
                      {Y`I have read the above information`}
                    </Button>
                    <p className='nomargin'>{Y`Do you have questions? Call us on ${Y`AlpInfoPhone`} or send an email to ${Y`AlpInfoEmail`}`}</p>
                  </Col>
                </CardContent>
              </Card>
            )}
            <Row
              mediaQueries={mediaQueries}
              smFlexDirection='column'
              marginBottom='.9375rem'
            >
              <Box
                width='100%'
                marginRight='.9375rem'
                mediaQueries={mediaQueries}
                smMarginRight={0}
                marginBottom='.9375rem'
              >
                <p
                  style={{ marginTop: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: this.renderInfoText(reservation, status)
                  }}
                />
                {status &&
                  !this.isCancelled(reservation) &&
                  reservation.depositDueDate &&
                  !this.depositFeePaid(reservation) && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: Y`To secure your booking, the registration fee of ${currencyFormat.format(
                          reservation.depositFee
                        )} must be delivered by ${moment(
                          reservation.depositDueDate
                        ).format(
                          'YYYY-MM-DD HH:mm'
                        )}. Do you have any questions about your booking? Call us at ${Y`AlpInfoPhone`} or send your question here: ${`<a target='_blank' href=${Y`AlpInfoContact`}>${Y`AlpInfoContact`}</a>`}`
                      }}
                    />
                  )}
                {status &&
                  !this.isCancelled(reservation) &&
                  !reservation.depositDueDate &&
                  !this.fullyPaid(reservation) && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: Y`To secure your booking, the full payment must be delivered by ${moment(
                          reservation.fullPaymentDueDate
                        ).format(
                          `YYYY-MM-DD${
                            moment(reservation.fullPaymentDueDate).format(
                              'Hms'
                            ) === '000' ||
                            moment
                              .parseZone(reservation.fullPaymentDueDate)
                              .format('Hms') === '000'
                              ? ''
                              : ' HH:mm'
                          }`
                        )}. Do you have any questions about your booking? Call us at ${Y`AlpInfoPhone`} or send your question here: ${`<a target='_blank' href=${Y`AlpInfoContact`}>${Y`AlpInfoContact`}</a>`}`
                      }}
                    />
                  )}
                {!status && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Y`Do you have any questions about your booking? Call us at ${Y`AlpInfoPhone`} or send your question here: ${`<a target='_blank' href=${Y`AlpInfoContact`}>${Y`AlpInfoContact`}</a>`}`
                    }}
                  />
                )}

                <Button link onClick={this.handleScrollToSummary}>
                  {Y`Manage booking`} &gt;&gt;
                </Button>
              </Box>
              {/* {this.renderInfoText(mediaQueries, reservation, currencyFormat, status)} */}

              <Col
                width='100%'
                marginLeft='.9375rem'
                mediaQueries={mediaQueries}
                smMarginLeft={0}
                justifyContent='center'
              >
                <ReservationSummaryBox
                  reservation={reservation}
                  currencyFormat={currencyFormat}
                  isCancelled={this.isCancelled(reservation)}
                  isDepositFeePaid={this.depositFeePaid(reservation)}
                  isFullyPaid={this.fullyPaid(reservation)}
                />
                {!this.hasChanges(reservation) && (
                  <React.Fragment>
                    {!this.fullyPaid(reservation) &&
                      !this.isCancelled(reservation) && (
                        <Button
                          style={{ whiteSpace: 'normal' }}
                          fullWidth
                          cy-id='payment-alternatives-button'
                          primary
                          onClick={() =>
                            this.handleToggleDialog(
                              !this.depositFeePaid(reservation)
                            )
                          }
                        >
                          <Icon style={{ marginRight: '.4687rem' }}>lock</Icon>
                          {this.depositFeePaid(reservation)
                            ? Y`Pay`
                            : Y`Secure your trip - pay ${currencyFormat.format(
                                reservation.depositFee
                              )} now!`}
                        </Button>
                      )}
                    {!this.depositFeePaid(reservation) &&
                      !this.isCancelled(reservation) && (
                        <Button
                          style={{ whiteSpace: 'normal' }}
                          link
                          onClick={() => this.handleToggleDialog()}
                        >{Y`...or click here to pay the entire trip direct`}</Button>
                      )}
                  </React.Fragment>
                )}
              </Col>
            </Row>
            <Box component='h3' textAlign='center' marginBottom='0px'>
              {Y`Overview`} {Y`Your trip`}
            </Box>
            <ReservationSummary showTravelers showTotalPrice showEditHeading />
          </Content>
        </Layout>
      </Page>
    )
  }
}

ReservationDetailsPage = connect(mapStateToProps)(ReservationDetailsPage)

export default ReservationDetailsPage
